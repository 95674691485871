import React, {useCallback} from "react";
import Fetch from "SB/fetch"


const Remove = ({avatar, setAvatar, deletePath, ondelete}) => {

  const handleClick = useCallback(() => {
    var last = avatar.avatar;
    setAvatar({
      ...avatar,
      avatar: null,
    });  

    Fetch.get(deletePath)
    .then(() => {
      if (ondelete) {
        ondelete();
      }
    })
    .catch(e => {
      alert(e.message);
      setAvatar({
        ...avatar,
        avatar: last,
      });  
    });
  }, [avatar, setAvatar, deletePath, ondelete]);


  if (!avatar || !avatar.avatar || !deletePath) {
    return null;
  }

  return (
    <button 
      type="button" 
      className="image-uploader-delete"
      onClick={handleClick}
    >Delete</button>
  );
}

export default Remove;
