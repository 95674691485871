class SB
{
    static get(url, token)
    {
        var promise = new Promise(function(resolve, reject)
        {
            let req = new XMLHttpRequest();
            req.open("GET", url);
        
            SB._ajaxHandle(req, token, resolve, reject);
 
            req.send();
        });

    /*
        promise.catch(function(error)
        {
            alert(error.message);
        });
    */

        return promise;
    }

    static post(url, data, token)
    {
        var promise = new Promise(function(resolve, reject)
        {
            let req = new XMLHttpRequest();
            req.open("POST", url);
 
            SB._ajaxHandle(req, token, resolve, reject);
 
            var formData;
            if (data instanceof FormData) {
                formData = data;
            } else if (data instanceof HTMLFormElement) {
                formData = new FormData(data);
            } else if (data instanceof Object) {
                formData = new FormData();
                Object.keys(data).forEach(key => formData.append(key, data[key]));
            } else {
                formData = data;
            }

           // req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
 
            req.send(formData);
        });

    /*
        promise.catch(function(error)
        {
            alert(error.message);
        });
    */

        return promise;

    }

    static _ajaxHandle(req, token, resolve, reject)
    {
        if (token) {
            token.xhr = req;
            token.abort = function()
            {
                req.abort();
                reject(new AbortError());
            };

            if (token.events) {
                for (let name in token.events) {
                    req.addEventListener(name, token.events[name]);
                }            
            }

            token.on = function(name, callback)
            {
                req.addEventListener(name, callback);
                return this;
            };

            // upload events don't fire if added after send is initiated
            if (token.upload) {
                for (let name in token.upload.events) {
                    req.upload.addEventListener(name, token.upload.events[name]);
                }            
            }
        }

        req.onload = function()
        {
            var type = req.getResponseHeader('content-type');

            var data = null;
            if (type === "application/json") {
                try {
                    data = JSON.parse(req.responseText);
                    
                    if (data.type === "error") {
                        reject(new Error(data.message));
                        return;
                    }

                } catch (e) {
                    e.message = "Invalid data returned from server";
                    reject(e);
                    return;
                }
            } else {
               // data = req.responseText;
                reject(new Error(req.responseText));
                return;
            }

            if (req.status === 200) {
                resolve(data);
            } else {
                reject(new Error(req.statusText));
            }
        };
 
        req.onerror = function()
        {
            if (req.status !== 0) {
                reject(new Error("Error connecting to server"));
            }
        };
    }

    static escapeHTML(html)
    {
        var text = document.createTextNode(html);
        var div = document.createElement('div');
        div.appendChild(text);
        return div.innerHTML;
    }

    static attr(attributes)
    {
        var list = [];
        for (var name in attributes) {
            var value = attributes[name];
            list.push(`${ name }=${ JSON.stringify(SB.escapeHTML(value)) }`);
        }
        return list.join(" ");
    }

    static isEmptyObject(obj) {

        /* eslint-disable no-unused-vars */
        var name;

        for ( name in obj ) {
            return false;
        }
        return true;
    }

    static classes(array)
    {
        array = array.filter((el) => { return el; });
    
        return array.join(" ");
    }

    static decodeAttr(value) {
        var div = document.createElement("div");
        div.innerHTML = value;
        return div.innerHTML.replace(/\\r?\\n/g, "\n");    
    }

    /***
    * Strip indentation from from template literals
    * based on: https://github.com/MartinKolarik/dedent-js/blob/master/src/index.ts
    **/ 
    static dedent (templateStrings, ...values) {
        let matches = [];
        let strings = typeof templateStrings === 'string' ? [ templateStrings ] : templateStrings.slice();
    
        // 1. Remove trailing whitespace.
        strings[strings.length - 1] = strings[strings.length - 1].replace(/\r?\n([\t ]*)$/, '');
    
        // 2. Find all line breaks to determine the highest common indentation level.
        for (let i = 0; i < strings.length; i++) {
            let match;
    
            match = strings[i].match(/\n[\t ]+/g);
            if (match) {
                matches.push(...match);
            }
        }
    
        // 3. Remove the common indentation from all strings.
        if (matches.length) {
            let size = Math.min(...matches.map(value => value.length - 1));
            let pattern = new RegExp(`\n[\t ]{${size}}`, 'g');
    
            for (let i = 0; i < strings.length; i++) {
                strings[i] = strings[i].replace(pattern, '\n');
            }
        }
    
        // 4. Remove leading whitespace.
        strings[0] = strings[0].replace(/^\r?\n/, '');
    
        // 5. Perform interpolation.
        let string = strings[0];
    
        for (let i = 0; i < values.length; i++) {
            string += values[i] + strings[i + 1];
        }
    
        return string;
    }
}

export class SbToken
{
    constructor()
    {
        this.events = {};
        this.upload = new SbTokenUpload();
    }

    on(name, callback)
    {
        this.events[name] = callback;    
        return this;
    }
}

class SbTokenUpload
{
    constructor()
    {
        this.events = {};
    }

    on(name, callback)
    {
        this.events[name] = callback;    
        return this;
    }
}


// custom error handler for abort error
export function AbortError(message) {
  this.name = 'AbortError';
  this.message = message || 'Action aborted';
  this.stack = (new Error()).stack;
}
AbortError.prototype = Object.create(Error.prototype);
AbortError.prototype.constructor = AbortError;


/*
class AbortError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AbortError';
  }
}
*/

/**
* override class to supply default value to second argument of then
*/
/*
class SBPromise extends Promise
{
    then(onFulfilled, onRejected)
    {
        if (!onRejected) {
            onRejected = function(e)
            {
                alert(e.message);
            };
        }
        Promise.prototype.then.call(this, onFulfilled, onRejected);
    }
}
*/

export {SB, SB as default}; 
