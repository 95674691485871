// import $ from "jquery";
import { MediaQuery }  from 'foundation-sites/js/foundation.util.mediaQuery';

//Sets up a slider menu and connects a menu button. Attaches click event to
//buttonSelector that toggles an 'open' class on itself and navContainerSelector
//If breakpoint is met or exceeded, the open class is removed
//navContainerSelector can be as far up the DOM as you want, as long as 
//there a .nav-primary is found in its descendants
export function basicSlideMenu(navContainerSelector, buttonSelector, breakpoint) // eslint-disable-line no-unused-vars
{
    var mobileMenu = new responsiveMenu(navContainerSelector);
    //need to run this after the dom is loaded because Foundation doesn't
    //set the breakpoints until then
    $(function()
    {
        var $navCont = $(navContainerSelector);
        var $button = $(buttonSelector);

        //initialize slide menu for < breakpoint devices
        mobileMenu.slider(breakpoint, {
            onEnabled: function() 
            {
                //close menu when link clicked (in case link is anchor)
                $navCont.find(".nav-primary li:not(.has-sub-menu) > a")
                    .each(function() {this.addEventListener("click", toggleMenu);});
            },
            onDisabled: function()
            {
                //toggle classes if the menu disables itself
                //(usually from media query changes)
                toggleMenu(false);
                $navCont.find(".nav-primary li:not(.has-sub-menu) > a")
                    .each(function() {this.removeEventListener("click", toggleMenu);});
            }
        });

        //toggle classes used for primary menu
        $button.on("click", function(e)
        {
            e.preventDefault();
            toggleMenu();
        });

        function toggleMenu(toggle)
        {
            $button.toggleClass("open", toggle);
            $navCont.toggleClass("open", toggle);
        }
    });
    return mobileMenu;
}

export function responsiveMenu(selector)
{
    var cont = $(selector);
    var self = this;
    
    // Detect IE8 which does not support this feature. 
    var support = window.matchMedia("all and (min-width:1px)").matches;

    //breakpoint: at what point to disable the menu (eg: medium)
    //listeners: calls the functions onEnabled and on Disabled
    //  onEnabled: When the menu is active (screensize < breakpoint)
    //  onDisabled: When the menu is inactive (screensize >= breakpoint)
    //Note that this is NOT 'onOpened'. You must show / hide this menu yourself.
    this.slider = function(breakpoint, listeners)
    {
        cont.addClass('slide-menu');
        var primaryNav = cont.find('.nav-primary');
        self.enabled = false;

        var callbacks = $.extend(true, {
            onEnabled : function(){},
            onDisabled: function(){}
        }, listeners);

        var traverseMenu = function(menuNode, depth) 
        {
            depth = depth || 0;
            menuNode.data("depth", depth);
            if(depth > 0) {
                var titleNode = menuNode.siblings('a').clone();
                titleNode.addClass("nav-section-title");
                menuNode.after(titleNode);
            }
            menuNode.children("li").each(function() 
            {
                if($(this).hasClass('is-active') && !$(this).hasClass('has-sub-menu')) {
                    activeSection = $(this).parent();
                } else {
                    activeSection = traverseMenu($(this).children('.sub-menu'), depth + 1);
                }
            });
            return activeSection || false;
        };
        var activeSection = traverseMenu(primaryNav);

        var setSection = function(section) 
        {
            cont.find('.previous').removeClass('previous');
            cont.find('.current').removeClass('current');
            cont.find('.contains-current').removeClass('contains-current');
            var depth = section.data("depth");
            primaryNav.css("transform", "translateX(-"+ depth * 100 +"%)");
            activeSection.addClass('previous');
            activeSection = section;
            section.parents('.sub-menu').addClass('contains-current');
            section.addClass('current');
        };

        var nextSectionHandler = function(e) 
        {        
            e.preventDefault();
            setSection($(this).siblings(".sub-menu"));
            return false;
        };

        var prevSectionHandler = function(e) 
        {
            e.preventDefault();
            var section = activeSection.parent().closest('ul');
            setSection(section);
            return false;
        };


    /*
        var getDepth = function(node) 
        {
            var count = 1;
            for (var p = node; p && !p.hasClass('nav-primary'); p = p.parentNode) {
                if ($(p).hasClass('sub-menu')) {
                    count++;
                }
            }
            return count;    
        };
    */

        self.enable = function() 
        {
            if(!self.enabled) {
                self.enabled = true;
                if(activeSection === false)
                    activeSection = primaryNav;
                setSection(activeSection);
                primaryNav.find("li.has-sub-menu > a:not(.nav-section-title)")
                    .each(function() {this.addEventListener("click", nextSectionHandler);});
                primaryNav.find("a.nav-section-title")
                    .each(function() {this.addEventListener("click", prevSectionHandler);});
                callbacks.onEnabled();
            }
        };
        self.disable = function() 
        {
            self.enabled = false;
            primaryNav.css("transform", "");
            primaryNav.find("li.has-sub-menu > a:not(.nav-section-title)")
                .each(function() {this.removeEventListener("click", nextSectionHandler);});
            primaryNav.find("a.nav-section-title")
                .each(function() {this.removeEventListener("click", prevSectionHandler);});
            callbacks.onDisabled();
        };

        if(!MediaQuery.atLeast(breakpoint)) {
            self.enable();
        }

        $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) 
        {
            if(MediaQuery.atLeast(breakpoint)) {
                self.disable();
            } else {
                self.enable();
            }
        });
    };

    this.flyout = function(mediaQuery, userOptions)
    {
        var options = $.extend(true, {
            'maxDepth' : 2,
            'isDefault' : false
        }, userOptions);

        
        var matchClass = function()
        {
            $(".has-menu, .has-sub-menu", cont).find(".sub-menu").each(function()
            {
                if (query.matches) {
                    $(this).addClass('sb-flyout-menu');
                } else {
                    $(this).removeClass('sb-flyout-menu');
                    $(this).removeAttr('style');
                }
            });
        };
        
        var query;
        if (mediaQuery) {
            query = window.matchMedia(mediaQuery);
            query.addListener(function()
            {
                matchClass();
            });
        } else {
            options.isDefault = true;
            query = {
                matches : true
            };
        }

        matchClass();

        cont.on("mouseenter", ".has-menu, .has-sub-menu", function()
        {
            if (!query.matches && !(support === false && options.isDefault === true)) {
                return;
            }
            if (self.getDepth(this) >= options.maxDepth) {
                return;
            }

        /*
            if ($(document.body).hasClass("mobile")) {
                return;
            }
        */

            removeTimeout(this, 'timeout');

          //  var depth = self.getDepth(this);
            var node = $(this);
            if (node.hasClass('menu-open')) {
                return;
            }

            var timeout = setTimeout(function()
            {
                node.siblings('.menu-open').each(function()
                {
                    close(this);
                });

                node.addClass('menu-open');
                var menu = $("> .sub-menu", node);            

                var right = node.offset().left + node.width() + menu.width();
                if (node.parent().closest(".menu-open").length) {
                // node.css('position', 'relative');

               
                    var direction;
                    if (($(window).width() <= right) || menu.parents('.force-left').length) {
                        direction = 'right';
                    } else {
                        direction = 'left';
                    }
                    

                    menu.css(direction, node.width());
                    menu.css('top', node.position().top);
                    menu.finish().animate({'width' : 'show'}, 200);
                } else {
                    if (($(window).width() <= right) || menu.parents('.force-left').length) {
                        menu.css('right', 0);
                    }

                    menu.finish().animate({'height' : 'show'}, 200);
                }
            }, 250);

            $(this).data('openTimeout', timeout);
        });

        cont.on("mouseleave", ".has-menu, .has-sub-menu", function(evt)
        {
            if (!query.matches && !(support === false && options.isDefault === true)) {
                return;
            }

            if (self.getDepth(this) > options.maxDepth) {
                return;
            }

            removeTimeout(this, 'openTimeout');
            var node = this;

         //   var siblings = $(this).siblings();
         //   siblings = siblings.add(siblings.children('a'));

            var timeout = setTimeout(function()
            {
                close(node);
            }, 500);
            $(this).data('timeout', timeout);
        });
    };

    this.accordion = function(mediaQuery, userOptions)
    {
        var options = $.extend(true, {
            'maxDepth' : 4,
            'isDefault' : false,
            'auto_reveal' : true
        }, userOptions);

        var matchClass = function()
        {
            $(".has-menu, .has-sub-menu", cont).find(".sub-menu").each(function()
            {
                if (query.matches) {
                    $(this).addClass('sb-accordion-menu');
                } else {
                    $(this).removeClass('sb-accordion-menu');
                }
            });
        };

        var query;
        if (mediaQuery) {
            query = window.matchMedia(mediaQuery);
            query.addListener(function()
            {
                matchClass();
            });
       } else {
            options.isDefault = true;
            query = {
                matches : true
            };
        }

        matchClass();

        /* Open menu to current page
        */
        if (options.auto_reveal && query.matches) {
            cont.find(".on").closest(".has-menu, .has-sub-menu")
            .addClass("menu-open")
            .children(".sub-menu")
            .show();
        }

        cont.on("click", ".has-menu, .has-sub-menu",  function(evt)
        {
            if (!query.matches && !(support === false && options.isDefault === true)) {
                return;
            }

            if (self.getDepth(this) > options.maxDepth) {
                return;
            }

        /*
            if (!$(document.body).hasClass("mobile")) {
                return;
            }
        */
        
          //  var li = $(evt.target).closest('li');
            var parent = $(evt.target).parent();
            if (parent.hasClass('has-menu') === false && parent.hasClass('has-sub-menu') === false) {
                return;
            }

            var node = $(this);
            var menu = $("> .sub-menu", node);

            /* Close Nearby menus
            */
            node.siblings('.menu-open').each(function()
            {
                $(this).removeClass('menu-open');
                $("> .sub-menu", this).finish().slideUp(200);
            });

            if (node.hasClass("menu-open")) {
                node.removeClass('menu-open');
                menu.finish().slideUp(200);
            } else {
                node.addClass('menu-open');
                menu.stop(1, 1).slideDown(200);
            }

            evt.preventDefault();
            evt.stopPropagation();
        });
    };

    var removeTimeout = function(node, name)
    {
        var timeout = null;
        if ((timeout = $(node).data(name))) {
            $(node).data(name, '');
            clearTimeout(timeout);
        }
    };

    var close = function(li)
    {
        var node = $(li);
        node.removeClass('menu-open');
        node.data('timeout', '');

        var menu = $("> .sub-menu", node);
        if (node.parent().closest(".menu-open").length) {
            menu.finish().animate({width : 'hide'}, 200, function()
            {
                menu.css({
                    'left' : '',
                    'top' : '',
                    'right' : ''
                });
            });
        } else {
            menu.finish().slideUp(200);
        }
    };

    this.getDepth = function(node)
    {   
        var count = 1;
        // KS: this breaks admin menus (like the delete list)
        // for (var p = node; p && p.nodeType == 1 && !p.classList.contains('nav-primary'); p = p.parentNode) {
        for (var p = node; p && p.nodeName !== 'NAV'; p = p.parentNode) {
            if (p.nodeName === 'LI') {
            // if (p.classList.contains('sub-menu')) {
                count++;
            }
        }
        return count;    
    };
}

export function responsiveMenuButton(icon, nav) // eslint-disable-line no-unused-vars
{
    icon = $(icon);
    nav = $(nav);
        
    icon.addClass('sb-mobile-nav');

    icon.on('click').on('click', function()
    {
        $(".sb-mobile-nav-open").removeClass("sb-mobile-nav-open");

        var open = $(".sb-mobile-nav-list-open");

        if (open.length) {
            open.finish().slideUp(function()
            {
                open.removeClass("sb-mobile-nav-list-open");
            
                if (!open.is(nav)) {
                    nav.addClass("sb-mobile-nav-list-open");
                    nav.finish().slideDown();
                } 
            });
        } else {
            icon.addClass("sb-mobile-nav-open");
            nav.addClass("sb-mobile-nav-list-open");
            nav.finish().slideDown();
        }
    });
}


global.basicSlideMenu = basicSlideMenu;
