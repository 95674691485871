/****************************************************
* Handle multilingual 
****************************************************/

const lang = (language, text) => {
  if (typeof(text) === "string") {
    return text;
  }

  if (text instanceof Object === false) {
    return "";
  }

  if (language instanceof Object) {
    language = language.lenguage;
  }

  if (!language && typeof(document) && document.body) {
    language = document.body.parentNode.getAttribute('lang');
  }
  
  return text[language] || text[DEFAULT_LANGUAGE] || "";
}

export default lang;
