$(document).ready(function()
{
    if (window.CKEDITOR && window.CKEDITOR.sb) {
        var cke = window.CKEDITOR;

        cke.on('instanceCreated', function(evt) {
            var editor = evt.editor;
            var config = editor.config;

            config.contentsCss = '/bundles/site/css/rte.css';
        });

        cke.sb.styles._styles = [
            /* Block Styles */
            { name: 'Paragraph', element: 'p' },
            { name: 'Heading 2', element: 'h2', attributes: { 'class': 'rte' } },
            { name: 'Heading 3', element: 'h3', attributes: { 'class': 'rte' } },
            { name: 'Small', element: 'p', attributes: {'class': 'rte small-text'} },
        ];
    }
});
