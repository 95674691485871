const handleResponse = async response => {
  var contentType = response.headers.get('content-type');
  
  if (contentType !== "application/json") {
    throw Error("Invalid response:\n" +  await response.text());
  }    

  var json = await response.json();
  if (json.type === "error") {
    throw Error(json.message);    
  }
  return json;
}; 


const post = (url = '', data) => {
  var formData;
  if (data instanceof FormData) {
    formData = data;
  } else if (data instanceof HTMLFormElement) {
    formData = new FormData(data);
  } else if (data instanceof Object) {
    formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
  } else {
    formData = data;
  }

  return fetch(url, {
    method: 'POST', 
    mode: 'cors', 
    cache: 'no-cache', 
    credentials: 'same-origin', 
    headers: {
      // 'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow',
    referrer: 'no-referrer', 
    body: formData, 
  })
  .then(handleResponse);
}

const get = (url = '') => {
  return fetch(url, {
    method: 'GET', 
    mode: 'cors', 
    cache: 'no-cache', 
    credentials: 'same-origin', 
    headers: {
      // 'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow',
    referrer: 'no-referrer', 
  })
  .then(handleResponse);
}

const Fetch = {
  post: post,
  get: get,
}
export default Fetch;

export {post, get};
