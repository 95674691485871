/* Memcache like set of caching functings that use DOM Storage
*/
var Cache = {
    'cache' : window.localStorage || {},
    'store' : function(name, value, ttl)
    {
        if (!ttl) {
            ttl = null;
        } else {
            var date = new Date();
            ttl = date.getTime() + (ttl * 1000);
        }

        var key = "Cache_" + name;

        var store = {
            'value' : value,
            'ttl' : ttl
        };

        if (window.JSON) {
            this.cache[key] = JSON.stringify(store);
        } 
    },
    'fetch' : function(name)
    {
        var key = "Cache_" + name;
        
        if (this.cache[key] && window.JSON) {
            var data = JSON.parse(this.cache[key]);
            
            if (data.ttl === null) {
                return data.value;
            } else {
                var date = new Date();
                var now = date.getTime();
                if (data.ttl < now) {
                    delete this.cache[key];
                    return null;
                }
                return data.value;
            }
        } else {
            return null;
        }
    },
    'remove' : function(name)
    {
        var key = "Cache_" + name;
        if (key in this.cache) {
            delete this.cache[key];
            return true;
        }
    },
    'gc' : function()
    {
        if (!window.JSON) {
            return;
        }

        var date = new Date();
        var now = date.getTime();

        for (var key in this.cache) {
            if (key.substr(0, 6) === 'Cache_') {
                var data = JSON.parse(this.cache[key]);
                if (data.ttl && data.ttl < now) {
                    delete this.cache[key];
                }
            }
        }
    }
};

export default Cache;
