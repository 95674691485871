import jqueryForm from "SB/forms/jquery.form.js";
// import {$, jQuery} from "jquery";
import lang from "SB/lang";


jqueryForm.addEnhancement(function()
{
    if (this.form.data('enhanced')) {
        return;
    }

    var handler = new errorHandler(this);

    this.errorHandler = handler;
});

function errorHandler(jqueryForm)
{
    this.jqueryForm = jqueryForm;
    this.jqueryForm.lock = false;
    
    this.form = jqueryForm.form;

    // Form is set not to validate
/*
    if (this.form.prop('noValidate') || this.form.attr('novalidate')) {
        return;
    }
*/

    var self = this;
    this._validities = [];
    // this._last_field = null;

    this.submitPressed = false;
    
    this.messages = {
        'required' : {
          en: 'Please fill out this field',
          fr: 'Veuillez remplir ce champ',
        },
        'email' : 'Please provide a valid email address',
        'date' : 'Date must be in YYYY-MM-DD Format',
        'pattern' : 'Please match the required format'
    };


    // Prevent browser based form validation
//    this.form.prop('noValidate', true);

    // checkValidity()
    // setCustomValidity()
    var input = document.createElement('input');
    try {
        input.type = 'date';
    } catch (e) {} // eslint-disable-line no-empty
    this.hasDate = input.type === 'date' ? true : false;

    this.form
 /*
    .on('input', ':input', function(evt)
    {
        var input = $(this);
        if (input.hasDialog('#form-error-dialog')) {
            input.dialogClose();
        }
    })
*/
    .on('change', ':input', function(evt)
    {
       // if (evt.type == "change" && (this.type != "radio" && this.type != "checkbox")) {
       //     return;
       // }

        var input = this;
       // var name = this.name;

        var $tags = $(input.nodeName + '[name="' + input.name + '"]', input.form);
        $tags.addClass('user-interacted');
 
        var $input = $(input);
        var $cont = self.getCont($input);

        // put in a delay to allow onchange events to fire
        setTimeout(() => {
            if (self.checkValidity(input)) {
                $cont.removeClass("has-error-message");
                $cont.find(".input-error").remove();
            } else {
                $cont.addClass("has-error-message");
                self.errorMessage(input);
            }
        }, 100);

    /*
        if ($(input).hasDialog('#form-error-dialog')) {
            $(input).dialogClose();
        }
    */

        // self._last_field = this;
 
        // USE timeout to get around multiple event fires on radio / checkboxes
    /*
        if (self._blurTimeout) {
            clearTimeout(self._blurTimeout);
            self._blurTimeout = null;
        }
        self._blurTimeout = setTimeout(function()
        {
            self._blurTimeout = null;           
            self._last_field = null;
        }, 100);
    */
    })
    .on('focus', ':input', function()
    {
        // Fixes a boug on radio buttons where clicking focuses & blurs a focused field
    /*
        if (self._last_field && self._last_field.name == this.name) {
            return;
        }
    */

       // var input = this;
    
    /*
        self.checkValidity(input);
        if ($(input).hasClass('user-error')) {
            self.errorMessage(input);
        }
    */

    /*
        self._focusTimeout = setTimeout(function()
        {
            self.checkValidity(input);
            if ($(input).hasClass('user-error')) {
                self.errorMessage(input);
            }

        }, 50);
    */
    });


    // Browser does not support oninvalid polyfill it
    this.form.on('input keyup click', ':input', function(evt)
    {
        /* Support for browsers that don't have a oninput event
        *  IE doesn't properly support oninput on delete / back space
        */
        if (evt.type === 'keyup'  && (evt.which !== 8  && evt.which !== 46)) {
            return;
        }

        var input = this;
        var $input = $(input);
        var $cont = self.getCont($input);

        var valid = self.checkValidity(this);

        if (!$input.hasClass('user-interacted')) {
            return;
        }

        if (valid) {
            $cont.removeClass("has-error-message");
            $cont.find(".input-error").remove();
        } else {
            $cont.addClass("has-error-message");
            self.errorMessage(input);
        }
    });

/*
    this.form.on('click', 'input[type="checkbox"], input[type="radio"]', function()
    {
        self.checkValidity(this);
    });
*/

    this.hasOnInvalid = this.supportsValidity();

    // Required Error for older browsers
    this.addCustomValidity('required', function(input, value)
    {
        console.log(input);
        switch ($(input).attr('type')) {
        case 'hidden': {
          let name = $(input).attr('name');
          if (name.includes('][')) { // checkbox list not supported yet
            return null;
          }
          let selector = 'input[name="' + name + '"][type="checkbox"]';
          let $cb = $(selector);
          if (!$cb) {
            return null;
          }
          if (!$cb.attr('required')) {
            return null;
          }
          if ($cb.prop('checked')) {
            return true;
          } else {
            return false;
          }
        }
        default:
          if (!$(input).attr('required')) {
            return null;
          }

          if (value.length === 0) {
            return false;
          } else {
            return true;
          }
        }

    }, this.messages.required);

    // min
    this.addCustomValidity('min', function(input, value, validity)
    {
        var $input = $(input);
        var min = $input.attr('min');
        if ($input.attr('type') !== "number" && $input.attr('type') !== "date") {
            return null;
        }

        if (!min && min !== "0") {
            return null;
        }

        if (value.length === 0) {
            return true;
        }

        if ($input.attr('type') === "date") {
            min = new Date(min);
            value = new Date(value);
        } else {
            min = parseFloat(min);
            value = parseFloat(value);
        }

        if (value >= min) { 
            return true;
        } else {
            validity.message = validity.message.replace("%s", min);
            return false;
        }

    }, {
      en: "This field must have a value of at least %s",
      fr: "Ce champ doit avoir une valeur d’au moins %s",
    });

    // max
    this.addCustomValidity('max', function(input, value, validity)
    {
        var max = $(input).attr('max');
        if ($(input).attr('type') !== "number") {
            return null;
        }

        if (!max && max !== "0") {
            return null;
        }

        max = parseFloat(max);

        if (value.length === 0) {
            return true;
        }

        value = parseFloat(value);

        if (value <= max) { 
            return true;
        } else {
            validity.message = validity.message.replace("%s", max);
            return false;
        }

    }, {
      en: "This field cannot exceed %s",      
      fr: "Ce champ ne peut pas dépasser %s",
    });


    // Email Matching for browsers that don't support it
    this.addCustomValidity('email', function(input, value)
    {
        if ($(input).attr('type') !== 'email') {
            return null;
        }

        // We may have pattern in the input; might as well use it if it's there:
        var multiple = $(input).attr('multiple') || false;
        var pattern = $(input).attr('pattern') || '';
        if (!pattern) {
            pattern = multiple ?
                '^$|^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]{1,63}\\.[a-zA-Z]{2,10}[,\\s]*)+$' :
                '^[A-Z0-9._%+-]+@[A-Z0-9.-]{1,63}.[A-Z]{2,10}$';
        }

        if (
            value.length 
            && !value.match(new RegExp(pattern, 'i'))
        ) {
            return false;
        } else {
            return true;
        }

    }, this.messages.email);

    // Pattern Matching for browsers that don't support it
    this.addCustomValidity('pattern', function(input, value)
    {
        var pattern = $(input).attr('pattern');

        if (!pattern) {
            return null;
        }

        if (input.value.length === 0) {
            return true;
        }

        if (!input.value.match(new RegExp(pattern))) {
            return false;
        } else {
            return true;
        }

    }, this.messages.pattern);

    // Value must match value of a different field
    this.addCustomValidity('match', function(input, value)
    {
        var id, field, inverse;

        /* Check match on inverse field
        */
        if ((inverse = $(input).data('matched_by'))) {
            this.checkValidity(inverse);
        }

        if (!(id = $(input).data('error-match'))) {
            return null;
        }
        
        field = $('#' + id);

        if (field.length === 0) {
            return null;
        }

        field.data('matched_by', input);

        if ($(input).val() !== field.val()) {
            return false;
        } else {
            return true;
        }

    }, "Field value does not match");


    // Date Matching for browsers that don't support it
    this.addCustomValidity('date', function(input, value)
    {
        if ($(input).attr('type') !== 'date') {
            return null;
        }

        if (
            value.length 
            && !value.match(/^[0-9]{4}-[0-1][0-9]-[0-3][0-9]$/)
        ) {
            return false;
        } else {
            return true;
        }
    }, this.messages.date);


    this.validate();

}

/* Safari has partial support for HTML5 validation. This allows us to test for actual support of the
*  oninvalid method so we can trigger it manually. 
*/
errorHandler.prototype.supportsValidity = function()
{
    var supported = false;
    var form = $('<form id="fake-form" style="display: none;"><input required="required"/><button type="submit"></form>').appendTo(document.body);

    $('input', form).on('invalid', function(evt)
    {
        supported = true;
        evt.preventDefault();
    });

    form.on('submit', function(evt)
    {
        evt.preventDefault();
    });

    $('button', form)
    .on("click", function(evt)
    {
        evt.stopPropagation();
    })
    .trigger('click');

    form.remove();

    return supported;
};

errorHandler.prototype.checkValidity = function(input)
{
    var $input = $(input);
    var $list;
    if (input.name) {
        $list = $(':input[name="' + input.name + '"]', input.form);
    } else {
        $list = $input;
    }

    if (
        this.form.prop('noValidate') 
        || this.form.attr('novalidate')
        || $input.attr('novalidate')
        || $input.prop('noValidate')
        || $input.prop('disabled')
        || $input.closest("fieldset").prop('disabled')
    ) {
        $list.removeClass('user-error invalid');
        this.removeValidity(input);
        return true;
    }

           
    var value = this.getValue(input);

    var isValid = true;
   // var type = 'unknown';

    var hasValidity = false;
    for (var i = 0, validity; (validity = this._validities[i]); i++) {

        console.log('Setting validity on ', input);
        console.log('Value: ', value);
        
        var valid = validity.callback.call(this, input, value, validity);

        hasValidity |= valid !== null;

        if (valid === true) {
            this.removeValidity(input);
        } else if (valid === false){
            this.setValidity(input, validity.message, validity.name);
            isValid = false;
            break;
        }
    }
    
    if (hasValidity) {
        $(input).addClass('has-validity');        
    } 

    // to handle fields like radio buttons that have multiple inputs with the same name
    if (isValid === true) {
    /*
        if ($(input).hasDialog('#form-error-dialog')) {
            $(input).dialogClose();
        }
    */
        $list.removeClass('user-error invalid');
        $list.addClass('valid');

        return true;
    } else {
        // Manually Trigger oninvalid event for browsers that don't support it

     //   if (false == self.hasOnInvalid) {
            var event = jQuery.Event("invalid");
            $list.trigger(event);
            if (event.isDefaultPrevented()) {
                $list.removeClass('user-error invalid');
                $list.addClass('valid');
                return true;
            }
     //   }

        $list.removeClass('valid');
        $list.addClass('invalid');

        if (this.submitPressed || $(input).hasClass('user-interacted')) {
            $list.addClass('user-error');
        }

        return false;        
    }
};

errorHandler.prototype.validate = function(selector)
{
    var query = selector ? $(selector) : this.form;
    var node = query.first();
   // var form = node.prop('nodeName') === 'FORM' ? node : node.closest('form');
    var list;

/*
    if (form.prop('noValidate') || form.attr('novalidate')) {
        return true;
    }
*/
    if (node.prop('nodeName') === 'FORM') {
        list = $(':input',  this.form);
    } else {
        list = query.filter(':input');
    }

    if (this.form.prop('noValidate') || this.form.attr('novalidate')) {
        return true;
    }

    var self = this;
    var valid = true;
    var checked = {};
//    $(':input',  this.form).each(function()
    list.each(function()
    {
        // We only want to check fields with same name once
        if (checked[this.name]) {
            return true;
        }
        checked[this.name] = true; 

        valid &= self.checkValidity(this);
    });

    return valid ? true : false;
};

errorHandler.prototype.addCustomValidity = function(name, callback, message)
{
    this._validities.push({
        'name' : name,
        'callback' : callback,
        'message' : lang(null, message)
    });
};

errorHandler.prototype.setValidity = function(input, jsMessage, type)
{
    var $input = $(input);
    var message = $input.data('error-message') ||  $input.data('message-' + type);

    if (input.type === 'radio') {
      const fieldSet = input.closest('fieldset');
      if (fieldSet) {
        message = fieldSet.getAttribute('data-error-message') || fieldSet.getAttribute('data-message-' + type);
      }
    }

    if (!message) {
      message = jsMessage;    
    }

    $(input).data('_error_type', type);
    $(input).data('_error_message', message);
/*
    if (input.setCustomValidity) {
        input.setCustomValidity(message);
    }
*/
};

errorHandler.prototype.removeValidity = function(input)
{
/*
    if (input.setCustomValidity) {
        input.setCustomValidity(null);
    }
*/

    $(input).removeData('_error_type');
    $(input).removeData('_error_message');
};

errorHandler.prototype.errorMessage = function(input, message)
{
    var $input = $(input);
   // var $cont = $input.closest(".input");
    var $cont = this.getCont($input);

    $cont.find(".input-error").remove();

    $cont.addClass("has-error-message");
    $cont.append('<div class="input-error">' + (message || $input.data('_error_message')) + '</div>');

/*
    if (jQuery.fn.dialogOpen) {
        var error = "<span>" + (message || input.data('_error_message')) + "</span>";
        var dialogOptions = {
            'stem' : true,
            'id' : 'form-error-dialog'
        };
        
        $(input).dialogOpen(error, dialogOptions);
    } else {
    }    
*/

};

errorHandler.prototype.getCont = function($input)
{
    var $form = $input.closest("form");
    
    if (!$form.length) {
        $form = $(document.body);
    }

    // Get the first one so consistent field (example if radio buttons are spread throught a form)
    var name = $input.prop("name");
    var $list = $form.find('[name="' + name + '"]');
    if ($list.length > 1) {
        $input = $list.first();
    }

    $input = $input.first();
    var $cont = $input.closest(".input");
    if (!$cont.length) {
        if ($input.closest('ul').length) {
            $cont = $input.closest('ul').parent();
        } else {
            $cont = $input.parent();
        }
    }
    return $cont;
};

errorHandler.prototype.getValue = function(input)
{
    var type = input.type;
    
    var query;
    if (input.name) {
        query = $(':input[name="' + input.name + '"]', input.form);
    } else {
        query = $(input);
    }

    var value = '';
    switch (type) {
    case 'radio':
    case 'checkbox':
        // last() only applies to checkboxes
        value = query.filter(':checked').last().val();
        break;
  //  case 'select-one': 
  //  case 'select-multiple':
    default:
        value = query.last().val();
    /*
        value = query.filter(function()
        {
            return $this.value.length ? true : false;
        })
    */

        break;        
    }

    return typeof(value) == 'undefined' ? '' : value;      
};

/* Notes: 

input.validationMessage - get the browser default validation message lame in Safari

*/

jQuery.fn.checkValidity = function(trigger, focus)
{
    var node = this.first();
    var form = node.prop('nodeName') === 'FORM' ? node : node.closest('form');

    if (form.prop('noValidate') || form.attr('novalidate')) {
        return true;
    }

    var self = form.data('jqueryForm');

    if (!self) {
        return true;
    }

    var handler = self.errorHandler;
    var retval = handler.validate(this);

    if (retval === false && trigger) {
        $(":input.invalid", form).addClass('user-error');
        var $input = $('.invalid', this).addBack(":input.invalid").first();
        if (focus) {
          var $list = $(":input.invalid", form).addClass('user-error');

          var names = [];
          $list.each(function() {
            if ($.inArray(this.name, names) === -1) {
              handler.errorMessage(this);
              names.push(this.name);
            }
          });


          // foo bar
          $input.focus();
          // Safari won't scroll to the input on radio buttons
          $.scrollTo($input.closest(".input"), {offset: -($(window).height() * 0.2)});
        }
    }

/* 
    if (node.prop('nodeName') == 'FORM') {
        var retval = handler.validate()
        if (retval == false && trigger) {
            $(":input.invalid", form).addClass('user-error');
            var input = $('.invalid', form).first();
            if (focus) {
                input.focus();
            }
        }
    } else {
        var retval = handler.checkValidity(node[0]);
        if (retval == false && trigger) {
            node.addClass('user-error');
            if (focus) {
                node.focus();
            }
        }
    }
*/
    return retval;    
};


/**
* Clear inline errors from form
*/
jQuery.fn.clearErrors = function()
{
    var $nodes = this.first();
    var $form = $nodes.prop('nodeName') === 'FORM' ? $nodes : $nodes.closest('form');

    // var self = $form.data('jqueryForm');

    $form.find(".has-error-message").removeClass("has-error-message");
    $form.find(".input-error").remove();
};

/**
* apply inline errors to form. used for handling ajax responses
*/
jQuery.fn.applyErrors = function(errors)
{
    var $nodes = this.first();
    var $form = $nodes.prop('nodeName') === 'FORM' ? $nodes : $nodes.closest('form');

    if ($form.prop('noValidate') || $form.attr('novalidate')) {
        return true;
    }

    // var self = $form.data('jqueryForm');

    var $input;
    for (let i = 0, error; (error = errors[i]); i++) {
        $input = $form.find("#" + error.id);
        $input.closest(".input")
        .append('<div class="input-error">' + error.message + '</div>');
    }
};



/*
 * Trigger error message for first selected field
 */
jQuery.fn.fieldError = function(message)
{
    var input = this.first();
    var form = input.closest('form');

    if (form.prop('noValidate') || form.attr('novalidate')) {
        return true;
    }

    var self = form.data('jqueryForm');

    if (!self) {
        return true;
    }

    var handler = self.errorHandler;
    handler.validate(this);

    if (message || $(input).hasClass('user-error')) {
        handler.errorMessage(input, message);
    }
};

/* Detect button press and submit form
*/
//$(document).on("click", 'button[type="submit"]:not([form]), button[type=""]:not([form]), button:not([type]):not([form]), input[type="submit"]:not([form]), input[type="image"]', function(evt)
$(document).on("click", 'button, input[type="image"], input[type="submit"]', function(evt)
{
    var self;
    var form;
    var button = $(this);
    if (button.attr('form')) {
        form = $("#" + $(button).attr('form'));
    } else {
        form = button.closest('form');
    }

    if (this.type && this.type !== "submit") {
        return;
    }

    if (!form.length || !(self = form.data('jqueryForm'))) {
        return;
    }

    var handler = self.errorHandler;

    handler.submitPressed = true;

    if (button.attr('novalidate') || button.prop('noValidate')) {
        return true;
    }

    if (handler.validate()) {
        form.data('submitting-form', true);
        var input = document.createElement('button');
        if (button.attr("formaction") && typeof(input.formaction) == "undefined") {
            // Polyfill formaction attribute
            form.prop("action", button.attr("formaction"));
        }

        if (handler.jqueryForm.lock && false === handler.jqueryForm.lock.checkSubmit()) {
            evt.preventDefault();    
        } else if (button.attr('form') &&  button.prop('form') !== form[0]) {
            // Polly browsers like IE & edge witch do not support this attribute
            if (this.name && this.value.length) {
                form.append('<input type="hidden" name="' + this.name + '" value="' + this.value + '"/>');            
            }
            form.submit();
        }


        return true;
    } else {
        var $list = $(":input.invalid", form).addClass('user-error');
        var $first = $(":input.invalid", form).addClass('user-error').first();

        var names = [];
        $list.each(function()
        {
            if ($.inArray(this.name, names) === -1) {
                handler.errorMessage(this);
                names.push(this.name);
            }
        });

        if ($first.closest(".sb-expander-head").length === 0) {
            $first.closest(".sb-expander")
            .addClass("sb-expander-open")
            .children(".sb-expander-body")
            .show();
        }
 
        if ($first.is(':hidden')) {
            $first.trigger('hidden-error-focus');
        } else {
          $first.focus();
          // Safari won't scroll to the input on radio buttons
          $.scrollTo($first.closest(".input"), {offset: -($(window).height() * 0.2)});
        }

        evt.preventDefault();    
    }

});

// Seems to be used on a few sites
global.errorHandler = errorHandler;
