// import {$, jQuery}  from "jquery";

var SbEscape = {
    _escapes: [],
    add: function(node, callback)
    {
        var index = SbEscape._escapes.length;

        if (node instanceof jQuery) {
            node = node[0];
        }

        SbEscape._escapes.push({
            index: index,
            callback: callback,
            node: node
        });

        return index;            
    },

    clear: function(index)
    {
        var length = SbEscape._escapes.length;

        if (typeof(index) == "undefined") {
            index = length - 1;
        }
  
        for (var i = index, data; (data = SbEscape._escapes[i]); i++) {
            data.callback.call(this, i);
        }

        SbEscape._escapes.splice(index, length - index);
    },
    
    clearAboveNode: function(node)
    {
        if (node instanceof jQuery) {
            node = node[0];
        }
        
        if (!node) {
            return;
        }

        var flag = false;
        for (var i = 0, data; (data = SbEscape._escapes[i]); i++) {
            if (node === data.node) {
                flag = true;
                continue;
            }

            if (flag === true) {
                SbEscape.clear(i);
                break;
            }
        }
    }, 
    
    contains: function(node)
    {
        if (node instanceof jQuery) {
            node = node[0];
        }
        
        if (!node) {
            return;
        }

        for (var i = 0, data; (data = SbEscape._escapes[i]); i++) {
            if (node === data.node || $.contains(data.node, node)) {
                return true;
            }
        }    

        return false;
    }, 

    closest: function(node)
    {
        if (node instanceof jQuery) {
            node = node[0];
        }
        
        if (!node) {
            return false;
        }

        for (var i = SbEscape._escapes.length - 1, data; (data = SbEscape._escapes[i]); i--) {
            if (node === data.node || $.contains(data.node, node)) {
                return data.index;
            }
        }    

        return false;
    }

};


$(document).ready(function()
{
    $(document).on('keydown', function(evt)
    {
        // Close dialog components on escape

        if (evt.which === 27) {
            if (SbEscape._escapes.length) {
                $(document.activeElement).blur(); // force input to fire blur event for ajax save
            }
            
            SbEscape.clear();
        }
    });
});

export default SbEscape;
