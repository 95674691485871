function addAnalytic(category, action, label) {
  if (window.ga_enabled && window.ga) {
    window.ga('send', 'event', category, action, label || '');        
  } else if (window.gtag) { 
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label || ''
    });
  }
}

export default addAnalytic;
global.addAnalytic = addAnalytic;
