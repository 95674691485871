// Help generate valid URL

import jqueryForm from "SB/forms/jquery.form.js";
// import $ from "jquery";

jqueryForm.addEnhancement(function()
{
    if (this.form.data('enhanced')) {
        return;
    }
    this.form.on('change', 'input[type=url]', function()
    {
        this.value = this.value.trim();
        
        if (this.value.length === 0) {
            return;
        }

        if (this.value.match(/^http/)) {
            return;
        }      
        
        if (this.value[0] === '/') {
            if (this.value[1] === '/') {
                return;
            }
            
            this.value = window.location.protocol + '//' + window.location.hostname + this.value;
        } else if (this.value.substr(0, window.location.hostname.length) === window.location.hostname) {
            this.value = window.location.protocol + '//' + this.value;
        } else if (this.value.match(/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])\/?[^ ]*$/)) {
    console.log('foo bar');
            this.value = 'http://' + this.value;
        }
        
        $(this).checkValidity(true);
    });
});
