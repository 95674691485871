$(function()
{
 //   var search_input = $('.search-input');
    var search_label = $('.search-label');
    var search_close = $('.search-close');

    search_label.on('click', function(event){
        var search_form = $(this).parents('.search-form');
        event.preventDefault();
        open_search(search_form);
        event.stopPropagation();
    });
    search_close.on('click', function(event){
        var search_form = $(this).parents('.search-form');
        close_search(search_form);
        event.stopPropagation();
    });

    // search_form.on('submit', function(e) {
    //  if ($(this).hasClass('search-collapse')) {
    //      e.preventDefault();
    //      open_search($(this));
    //  } else if ($(this).find('.keywords').val().length === 0) {
    //      e.preventDefault();
    //      close_search($(this));
    //  }       
    // });


    function open_search(search_form) {
        search_form.toggleClass('search-expand', true);
        search_form.find('.search-input').focus().trigger('input');
        $(window).on('click.CloseSearchForm', function(e) {
            if ($(e.target).is(search_form) || $.contains(search_form.get(0), e.target)) {
                return;
            }
            close_search(search_form);
        });
    }


    function close_search(search_form) {
        search_form.toggleClass('search-expand', false);
        search_form.find('.search-input').val('').trigger('input');
        search_form.find('.submit').toggleClass('search-close', false);
        $(window).off('click.CloseSearchForm');
    }
});
