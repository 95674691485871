// Help generate valid URL

import jqueryForm from "SB/forms/jquery.form.js";
// import $ from "jquery";

jqueryForm.addEnhancement(function()
{
    if (this.form.data('enhanced')) {
        return;
    }

    var setClass = function(key, input)
    {
        var $input = $(input);

        if (input.value.length || $input.hasClass('is-autofilled')) {
            $input.addClass('has-value').removeClass('is-autofilled');
        } else {
            $input.removeClass('has-value is-autofilled');
        }
    };

    this.form.find('input, textarea')
    .each(setClass)

    // XXX Workaround for chrome not firing events on autofill.
    .each((key, input) => {
        input.addEventListener('animationstart', (e) => {
            switch(e.animationName) {
            case 'onAutoFillStart':
                $(input).addClass('is-autofilled');
                setClass(null, input);
                return;
            case 'onAutoFillCancel':
                $(input).removeClass('is-autofilled');
                break;
            }
        });
    });

    this.form.on('input blur', 'input, textarea', function()
    {
        setClass(null, this);
    });
});
