/* globals YT, Vimeo */
// import {$, jQuery} from "jquery";
import SbEvents from "SB/js/SbEvents.js";

export class SBVideo
{
    constructor()
    {
        this.player = null;
        this.isReady = false;
    }

    load(callback)
    {
        if (!this.handler) {
            return false;
        }    

        this.handler.load(callback);
    }

    populateNode($node)
    {
        var self = this;
        var embed;
        try {
           // var node = $($node.data("code"));
            embed = $node.data("embed");

            if (!embed) {
                return;
            }
        } catch (e) {
            return;
        }    

        switch (embed.service) {
        case "youtube":
            this.service = "youtube";
            this.handler = SBYoutube;
            break;
        case "vimeo":
            this.service = "vimeo";
            this.handler = SBVimeo;
            break;
        }
    
        this.load(function()
        {
            switch (self.service) {
            case "youtube":

            var player;
            player = self.player = new YT.Player($node.find(".video").prop("id"), {
            /*
                width: img.width(),
                height: img.height(),
            */
                videoId: embed.id,
                events: {
                    'onReady': function (event)
                    {
                        self.isReady = true;
                        self.trigger("ready");
                        // player.playVideo();
                        //event.target.playVideo();
                    },
                    'onStateChange': function (event)
                    {
                      //  if (event.data == YT.PlayerState.PLAYING) {
                      //  }
                    }
                }
            });
    
                break;
            case "vimeo":
            player = self.player = new Vimeo.Player($node.find(".video")[0], {
                id: embed.id,  
            /*                
                width: img.width(),
                height: img.height(),
            */
                loop: false
            });

            player.on('pause', function()
            {
            });
            
            player.on('ended', function()
            {
            });
    
            player.on('play', function()
            {
            });
    
            player.ready()
            .then(function()
            {
                self.isReady = true;
                self.trigger("ready");
            });
            
                break;
            }        
        });
    }

    play()
    {
        var self = this;
        var player;

        var action = function()
        {
            if (!(player = self.player)) {
                return;
            }
    
            switch (self.service) {
            case "youtube":
                player.playVideo();
                break;
            case "vimeo":
                player.play();
                break;
            }
        };

        if (this.isReady) {
            action();        
        } else {
            this.on("ready", action);        
        }
    }

    pause()
    {
        var self = this;
        var player;
        
        var action = function()
        {
            if (!(player = self.player)) {
                return;
            }
    
            switch (self.service) {
            case "youtube":
                player.pauseVideo();
                break;
            case "vimeo":
                player.getPaused().then(function(paused)
                {
                    if (paused === false) {
                        player.pause();
                    }
                }).catch(function(error)
                {
                });
    
                break;
            }
        };

        if (this.isReady) {
            action();        
        } else {
            this.on("ready", action);        
        }
    }
}

var SBYoutube = {
    load: function(callback)
    {
        if (window.YT) {
            callback();
        } else if (SBYoutube.tag) {
            return;
        } else {
            var tag = SBYoutube.tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
     
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            // youtube_loaded = true;
     
            window.onYouTubeIframeAPIReady = function()
            {
                callback();
            };
        }
    }, 
    player()
    {
    }
};

var SBVimeo = {
    /**
    *  Lazy load vimeo
    */
    load: function(callback)
    {
        if (window.Vimeo) {
            callback();
        } else if (SBVimeo.tag) {
            SBVimeo.tag.addEventListener('load', function()
            {
                callback();
            });
        }  else {
            var tag = SBVimeo.tag = document.createElement('script');
            tag.src = 'https://player.vimeo.com/api/player.js';
        
            tag.addEventListener('load', function()
            {
                callback();
            });
            
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);    
        }
    },
    
    thumbnails: function(selector)
    {
        var $cont = $(selector);
    
        $(".vimeo-thumbnail", $cont).each(function()
        {
          //  var options = {
          //  };
    
            var img = $(this);
            var id = img.data('related-vimeo-id');
            
            var url = "https://vimeo.com/api/v2/video/" + id + ".json?callback=SBVimeoThumb";
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;

            img.before(script);
        });
    }
};

/**
* Load vimeo thumbnail via JSONP
**/
export function SBVimeoThumb(data)
{
    data = data[0];

    var div = $("#vimeo-thumbnail-" + data.id);

    div.append('<img src="' + data.thumbnail_large + '"/>');
}

/**
* Jquery plugin
*/
(function($)
{
    $.fn.video = function(action)
    {
        var player;
        var $node = this.first();

        if ($node.length === 0) {
            return $node;
        }

        if ((player = $node.data("player"))) {
            // Do nothing
        } else {
            player = new SBVideo();
            player.populateNode($node);
            $node.data("player", player);
        }

        switch (action) {
        case "load":
            break;
        case "play":
            player.play();
            break;
        case "pause":
            player.pause();
            break;
        }
        return $node;   
    };
})(jQuery);

Object.assign(SBVideo.prototype, SbEvents);

export { SBVimeo, SBYoutube };

global.SBVimeoThumb = SBVimeoThumb;
