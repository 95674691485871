// import $ from "jquery";

$(document).ready(function()
{

    $(document)
    .on("mouseenter", ".sb-tooltip-overflow", function(evt)
    {
        var $tooltip;
        var node = this;
        var $node = $(this);
        
        if ($node.data('tooltip-timeout')) {
            clearTimeout($node.data('tooltip-timeout'));
            $node.removeData('tooltip-timeout');
        }      

        if ($node.data('tooltip-clear-timeout')) {
            clearTimeout($node.data('tooltip-clear-timeout'));
            $node.removeData('tooltip-clear-timeout');
        }      

        if (node.clientWidth >= node.scrollWidth) {
            return;
        }        

        var timeout = setTimeout(function()
        {
          //  var bounds = node.getBoundingClientRect();
            
            var div = `<div class="sb-tooltip">${ node.innerHTML }</div>`;
            $tooltip = $(div);
            var $parent = $(getScrollParent(node));
            $node.data('tooltip', $tooltip);
           
            $tooltip.hide();
            $parent.append($tooltip);

            var offset = $node.offset();
            var parentOffset = $parent.offset();
    
            var top = offset.top - parentOffset.top + $parent.prop('scrollTop') + $node.height() + 5;
            var bottom = offset.top - parentOffset.top + $node.height() + 5;
            var left =  offset.left - parentOffset.left;
            var width = $tooltip.width();

            if (bottom > $parent.height()) {
                top = offset.top - parentOffset.top + $parent.prop('scrollTop') - $tooltip.height() - 5;
            }
            if (left + width > $parent.width()) {
                left+= $parent.width() - (left + width);
            }
        
            $tooltip.css({
                top: top,
                left: left,
            });
    
            

            $tooltip.fadeIn(300);


            $tooltip
            .on("mouseenter", function(evt)
            {
                if ($node.data('tooltip-timeout')) {
                    clearTimeout($node.data('tooltip-timeout'));
                    $node.removeData('tooltip-timeout');
                }      
 
                 if ($node.data('tooltip-clear-timeout')) {
                    clearTimeout($node.data('tooltip-clear-timeout'));
                    $node.removeData('tooltip-clear-timeout');
                }      
        
            })
            .on("mouseleave", function(evt)
            {
                if (evt.relatedTarget === node) {
                    return;
                }

                var timeout = setTimeout(function()
                {
                    $node.removeData('tooltip');
                    $tooltip.fadeOut(300, function()
                    {
                        $tooltip.remove();
                    });
                }, 300);    
                $node.data('tooltip-clear-timeout', timeout);
            });

        }, 300);
        $node.data('tooltip-timeout', timeout);
        
    })
    .on("mouseleave", ".sb-tooltip-overflow", function(evt)
    {
        var $node = $(this);
        
        if ($node.data('tooltip-timeout')) {
            clearTimeout($node.data('tooltip-timeout'));
            $node.removeData('tooltip-timeout');
        }      

         if ($node.data('tooltip-clear-timeout')) {
            clearTimeout($node.data('tooltip-clear-timeout'));
            $node.removeData('tooltip-clear-timeout');
        }      

        var $tooltip = $node.data('tooltip');
        if ($tooltip && $tooltip.length) {
            var timeout = setTimeout(function()
            {
                $node.removeData('tooltip');
                $tooltip.fadeOut(300, function()
                {
                    $tooltip.remove();
                });
            }, 300);    
            $node.data('tooltip-clear-timeout', timeout);
        }
    });

    var getScrollParent = function(node)
    {
        for (let p = node; p && p.nodeType === 1; p = p.parentNode) {
            let $p = $(p);
            if (p === document.body) {
                return p;
            }
            
            if ($p.css('overflow-y') === 'scroll'  || $p.css('overflow-y') === 'auto') {
                return p;
            }
        }
    };
});
