// import "core-js/stable";
import "regenerator-runtime/runtime";
import jQuery from "jquery";

import Cache from "SB/cache/Cache.js";
import jqueryDialog from "SB/dialog/jquery.dialog.js";
import {basicSlideMenu, responsiveMenu, responsiveMenuButton} from "SB/js/responsiveMenu.js";
import "SB/js/help.js";
import "SB/js/tooltip.js";
import addAnalytic from "SB/js/addAnalytic";

import jqueryForm from "SB/forms/jquery.form.js";
import "SB/forms/plugins/modified.js";
import "SB/forms/plugins/confirm.js";
import "SB/forms/plugins/errors.js";
import "SB/forms/plugins/url.js";
import "SB/forms/plugins/has-value.js";


import "jquery.scrollto";


import SAYT from "SB/sayt/jquery.SAYT.js";
import SB from "SB/js/SB.js";
import "SB/js/video.js";
import CalloutVideo from "core/bundles/callouts/assets/js/video.js";
// import "SB/js/sharing-buttons.js";
import { MediaQuery }  from 'foundation-sites/js/foundation.util.mediaQuery'; // eslint-disable-line no-unused-vars
import initSayt from "core/bundles/search/assets/js/search.js";
import "core/bundles/search/assets/js/search-collapse.js";
import "core/bundles/sharingbuttons/assets/js/sharingbuttons.js";
import SbExpander from "core/bundles/articles/assets/js/expanders.js";
import SbEscape from "SB/js/escape.js";
import SbEvents from "SB/js/SbEvents.js";
import React from 'react';
// import ReactDOM from 'react-dom';

// window.addEventListener('unhandledrejection', function (event) {
//  console.warn('WARNING: Unhandled promise rejection: '+ event.reason, event.stack);
// });
  

global.$ = global.jQuery = jQuery;
global.basicSlideMenu = basicSlideMenu;
global.responsiveMenu = responsiveMenu;
global.responsiveMenuButton = responsiveMenuButton;
global.jqueryForm = jqueryForm;
global.Cache = Cache;
global.SAYT = SAYT;
global.addAnalytic = addAnalytic;
// global.objectFitImages = objectFitImages;
global.initSayt = initSayt;
global.CalloutVideo = CalloutVideo;
global.SbExpander = SbExpander;
global.jqueryDialog = jqueryDialog;
global.SB = SB;
global.SbEscape = SbEscape;
global.SbEvents = SbEvents;
// global.Slideshow = Slideshow;

// $(document).foundation();
MediaQuery._init();
global.Foundation = {MediaQuery: MediaQuery};
//global.ReactDOM = ReactDOM;
global.React = React;


//global.SbHydrate = (element, node) => {
//  import(/* webpackChunkName: "react-dom" */ "react-dom")
//  .then(({hydrate}) => {
//    hydrate(element, node);
//  });
//}; 

global.SbAdmin = () => {
  var p = import(/* webpackChunkName: "admin" */ "./admin")
  .then((obj) => {
 //   global.SbList = SbList;
    return obj;
  }) ;
  return p;
}

global.SbInlineList = () => {
  return import(/* webpackChunkName: "sb-inline-list" */ "SB/js/inline-list.js");
};

global.SbSlideshow = () => {
  return import(/* webpackChunkName: "sb-slideshow" */ "core/bundles/slideshow/assets/src/slideshow.js");
};


