/* globals YT, Vimeo */
import $ from "jquery";
import {SBVimeo, SBYoutube} from "SB/js/video.js";

export default function SBArticleVideo(selector)
{
    var self = this;
    var cont = $(selector);

    SBVimeo.thumbnails(cont);
    
    cont.on("click", ".embed", function()
    {
        var div = $(this);
        var img = div.find("img");
        var caption = div.find("figcaption");
        var imageDiv = div.find(".image");
        var embed;

        try {
          //  var node = $(div.data("code"));
            embed = div.data("embed");
        
            if (!embed) {
                return;
            }
        } catch (e) {
            return;
        }
        
        if (img.length) {
            var pos = img.offset();
            
            if (caption.length) {
                var captionPos = caption.offset();        
                if (pos.top + img.height() >  captionPos.top) {
                    caption.css('display', 'none');
                }
            }
        }

        if (div.hasClass("video-enabled")) {
            return;
        } 

        div.addClass("video-enabled");
        
        $(self).trigger("play");

        var video = div.find(".video");
        
        if (!video.length) {
            video = $('<div class="video flex-video" />');
            if (img.length) {
                var ratio = img.width() / img.height();
                if (ratio > 1.5) {
                    video.addClass("widescreen");
                }
            }
        }

        switch (embed.service) {
        case 'youtube':
            self.youtube(embed, div, imageDiv, video);
            break;
        case 'vimeo':
            self.vimeo(embed, div, imageDiv, video);
            break;
        }
    });

    this.youtube = function(embed, cont, image, video)
    {
        SBYoutube.load(function()
        {
            self.youtubePlayer(embed, cont, image, video);
        });
    };

    this.youtubePlayer = function(embed, cont, image, video)
    {
        var player;
        if ((player = video.data("player"))) {
            image.css('display', 'none');
            video.css('display', '');
            player.playVideo();
            return;
        }

        var img = image.find("img");
        var done = false;
        
        var id = "video-" + (new Date().getUTCMilliseconds());
        video.append('<div id="' + id + '" />');
        
        video.css('display', 'none');
        image.before(video);

       // var timeout = null;

        player = new YT.Player(id, {
            width: img.width(),
            height: img.height(),
            videoId: embed.id,
            events: {
                'onReady': function (event)
                {

                    image.css('display', 'none');

                    video.css('display', '');
                    event.target.playVideo();
                },

                'onStateChange': function (event)
                {
                /*
                    if (timeout) {
                        clearTimeout(timeout);
                        timeout = null;
                    }

                   if (event.data == YT.PlayerState.PAUSED) {
                    // pause and seek show up as the same
                        timeout = setTimeout(function()
                        {
                            if ( event.target.getPlayerState() == YT.PlayerState.PAUSED) {
                                player.stop();
                            }
                        }, 2000)
                    }

                    if (event.data == YT.PlayerState.ENDED) {
                        player.stop();
                    } 
                */
            
                    if (event.data === YT.PlayerState.PLAYING && !done) {
                        done = true;
                    }
                }
            }
        });

        player.stop = function()
        {
           player.pauseVideo();

            video.css('display', 'none');
            image.css('display', '');

            cont.removeClass("video-enabled");
            cont.find('figcaption').css('display', '');
        };


        video.data('player', player);
    };

    this.vimeo = function(embed, cont, image, video)
    {
        var self = this;
        SBVimeo.load(function()
        {
            self.vimeoPlayer(embed, cont, image, video);
        });
    };

    this.vimeoPlayer = function(embed, cont, image, video)
    {
        var player;
        if ((player = video.data("player"))) {
            image.css('display', 'none');
            video.css('display', '');
            player.play();

            return;
        }

        var img = image.find("img");

        video.css('display', 'none');
        image.before(video);
        
        image.css('display', 'none');
        video.css('display', '');

        var options = {
            id: embed.id,
            width: img.width(),
            height: img.height(),
            loop: false
        };

        player = new Vimeo.Player(video[0], options);
        
        player.on('pause', function()
        {
          //  player.stop();
        });

        player.on('ended', function()
        {
           // player.stop();
            // player.pause();

            // rewind to beginning
         //   player.setCurrentTime(0);
         //   player.play();
        });

        player.on('play', function()
        {
        });

        player.ready()
        .then(function()
        {

        });

        player.play();

        player.stop = function()
        {
            player.getPaused().then(function(paused)
            {
                if (paused === false) {
                    player.pause();
                }
            }).catch(function(error)
            {
            });

            video.css('display', 'none');
            image.css('display', '');

            cont.removeClass("video-enabled");
            cont.find('figcaption').css('display', '');
        };


        video.data('player', player);
    };
}
